import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Servizio from '../../../components/Servizio'

import FoodServiceImg from '../../../images/food_service.jpg'

const paginaServizio = {
  titolo: 'Food Service',
  parent: {
    nome: 'Accessori',
    linkUrl: '/servizi/servizi-accessori',
  },
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: FoodServiceImg,
      paragrafi: [
        {
          id: 1,
          testo:
            'FoodService è il servizio dedicato al trasporto della merce alimentare non deperibile trasportabile a temperatura ambiente che GLS effettua secondo la metodologia HACCP al fine di garantire un livello più elevato di sicurezza igienica dei prodotti trasportati.',
        },
      ],
      pesoDimensioni: null,
      vantaggi: [
        {
          id: 1,
          testo: 'Rispetto normative igienico sanitarie',
        },
        {
          id: 2,
          testo:
            'Adozione di misure atte a garantire la sicurezza e la salubrità dei prodotti alimentari',
        },
        {
          id: 3,
          testo: 'Controlli periodici su pulizia dei mezzi e aree magazzino',
        },
      ],
      compatibileCon: [
        {
          id: 1,
          nome: 'National Express',
          linkUrl: '/servizi/national-express',
        },
        {
          id: 2,
          nome: 'Parcel',
          linkUrl: '/servizi/parcel',
        },
        {
          id: 3,
          nome: 'Express',
          linkUrl: '/servizi/express',
        },
      ],
      servizi: null,
    },
  ],
}

const FoodService = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default FoodService
